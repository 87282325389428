/* green 7FC294 */
/* blue 1890ff */
.app input[type=text]:focus,
.app button:focus {
  outline: #1890ff auto 3px;
}

.text-themed {
  color: #1890ff;
}

.btn {
  font-weight: bold;
  color: #1890ff;
  border-color: #1890ff;
}

.btn-primary {
  color: #fff;
  background-color: #1890ff;
}
