.adjacency-matrix {
  font-size: 10px;
  margin: 0 auto;
  margin-top: 50px; /* make room for transformed labels */
}

/* header labels */
.adjacency-matrix thead td {
  transform: translateX(12px) rotate(-45deg);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.adjacency-matrix tbody td:first-child {
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}

.adjacency-matrix td {
  text-align: center;
  width: 21px;
  max-width: 21px;
}

.adjacency-matrix .ant-radio-wrapper {
  margin: 0;
}
