.response-view {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.response-view.ignored {
  opacity: 0.3;
}

.response-view-graph svg {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 80vh;
}
