body.body-survey-app {
  font-size: 16px;
}

/* using 100vh breaks connection editor screen on ios */
.body-survey-app,
.body-survey-app #root,
.body-survey-app .app {
  min-height: 100%;
}

button {
  cursor: pointer;
}

select {
  height: 2.5em;
  background: none;
}

.text-center {
  text-align: center;
}

.btn {
  padding: 10px 10px;
  border: 2px solid #000;
  border-radius: 2px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group input,
.form-group select {
  width: 100%;
}

/* .app {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.screen {
  position: relative;
}

.fullscreen {
  width: 100vw;
  height: 100vh;
}

.screen-media {
  display: block;
  width: 200px;
  max-width: 100%;
  margin: 0 auto;
}

.screen-heading {
  margin: 10px;
  margin-top: 20px;
  text-align: center;
}

.screen-heading aside {
  display: block;
  margin: 0;
  font-size: 0.8em;
  text-align: center;
  opacity: 1;
}

.connection-editor-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.connection-editor-screen .screen-body {
  flex: 1;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.screen-footer {
  text-align: center;
}

.screen-footer .help-text {
  margin: 0;
}

.screen-nav {
  padding: 10px 20px 20px;
  display: flex;
  justify-content: center;
}

.container {
  width: 90vw;
  max-width: 600px;
  margin: 0 auto;
}

.intake-form .form-group {
  margin-bottom: 4em;
}

.intake-form input[type=text] {
  border: 1px solid #ddd;
  border-radius: 3px;
  line-height: 3em;
  padding: 0 1em;
}

.intake-form .ant-radio-group {
  display: block;
}

.intake-form .ant-radio-wrapper,
.intake-form .ant-checkbox-wrapper {
  display: block;
  margin: 0;
  padding: 1em 0;
}

.input-list {
  list-style: none;
  padding-left: 30px;
}

/* display:flex on the li itself hides the bullet in chrome */
.input-list-group {
  display: flex;
  margin: 10px 0;
}

.input-list-group .text-input {
  flex: 1;
}

.input-list-group:before {
  content: "•";
  padding-right: 10px;
  margin-left: -30px; /* keep in sync with .input-list padding-left */
  line-height: 36px;
}

.input-list-group.unstyled:before {
  content: "";
}

/* input[type=text], */
.input-list-group input[type=text] {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ddd;
  padding: 1px 10px;
}

.input-list-group * {
  line-height: 36px;
}

.text-input {
  position: relative;
}

.autocomplete-list {
  z-index: 1;
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #eee;
  box-shadow: 0 0 5px #f9f9f9;
  background: #fff;
}

.autocomplete-list li {
  cursor: pointer;
  border-bottom: 1px solid #eee;
  padding: 0 10px;
}

.autocomplete-list li.active {
  /* background: #ddd; */
  font-weight: bold;
}

.delete-btn {
  background: none;
  border: 0;
  box-shadow: none;
  margin-left: 5px;
  padding: 0 10px;
}
