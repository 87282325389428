svg {
  overflow: visible !important;
}

svg * {
  pointer-events: none;
}

svg .node-hit {
  pointer-events: auto;
}

svg text {
  user-select: none;
}
