.mappings-editor {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.mappings-editor-main {
  height: 100vh;
  overflow: auto;
  padding: 40px 20px 80px 20px;
}

.mappings-editor-footer {
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: auto;
  padding: 10px;
  background: #fff;
  border-top: 1px solid #eee;
  box-shadow: 0 0 20px #eee;
}

.mappings-editor-footer .placeholder .ant-tag {
  width: 100%;
}

.mappings-list {
  list-style: none;
  padding: 0;
}

.mappings-editor .value {
  display: inline-block;
  user-select: none;
}

.mappings-editor-stats {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.drop-target {
  padding: 4px;
  border: 1px solid #fff;
  border-radius: 3px;
}

.draggable--original,
.draggable-source--is-dragging {
  opacity: 0.1;
}

.draggable-mirror {
  z-index: 1000;
}

.draggable-container--over {
  border-color: #e6f7ff;
  background-color: #e6f7ff;
}
