.admin-app .survey-view .logo {
  display: block;
  width: 30%;
  margin: 10vh auto 5vh;
}

.admin-app .survey-view .survey-name {
  margin: 0 10px 20px;
  color: #fff;
  text-align: center;
}

.admin-app .import-drop-target {
  transition: opacity ease-in-out 0.5s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.9);
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-app .import-drop-target * {
  pointer-events: none;
}

.admin-app .survey-view.dragging .import-drop-target {
  opacity: 1;
  pointer-events: auto;
}
