.responses-view {
  padding: 20px;
}

.responses-view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.responses-view-header .ant-input-group {
  display: inline-block;
  width: auto;
}

.responses-view-header .ant-select {
  width: 300px;
}

.responses-view-header .ant-btn-group {
  margin-left: 10px;
}
