.master-matrix {
  padding: 20px;
}

.master-matrix .filter-input {
  margin-bottom: 20px;
}

.master-matrix .adjacency-matrix {
  margin-bottom: 40px;
}
