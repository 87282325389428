.homepage .container {
  margin: 0 auto;
  width: 600px;
  max-width: 100%;
}

.homepage .block {
  margin-bottom: 10vh;
}

.homepage .text-center {
  text-align: center;
}

.homepage .hidden {
  display: none;
}

.homepage img {
  max-width: 100%;
}

.homepage .logo {
  display: block;
  width: 200px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 40px;
}

.homepage .process img {
  display: block;
  width: 100%;
  margin: 5vh auto;
}

.homepage .changes {
  margin-top: 20vh;
  font-style: italic;
  border-top: 1px solid red;
}

.homepage blockquote {
  color: #000;
  text-align: center;
  font-weight: 200;
  font-size: 1.5rem;
  line-height: 1.2em;
  margin: 50px 0;
  padding: 20px;
  border: 1px solid #eee;
  border-width: 1px 0;
}

.homepage footer .links {
  margin: 40px 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.homepage footer .links a {
  margin: 0 20px;
}
