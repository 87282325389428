a {
  color: #34b3eb;
}

.legal {
  margin-top: 100px;
  margin-bottom: 100px;
}

.legal .logo {
  display: block;
  width: 200px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.legal .block {
  margin-bottom: 100px;
}
